/* @flow */

import { shallowEqual, useSelector } from 'react-redux';

import { getEventId } from 'src/shared/domain/event/selector/eventSelectors';

export default function useEventId(): number {
  // Event schema doesn't depend on any other data for now, but the `getEvent`
  // selector returns a new event object whenever a part of the `entities` state
  // slice changes. Therefore it returns a new event object whenever the
  // entities are modified, even if the event data hasn't changed. To mitigate
  // that, we use a shallow comparison instead of the default strict comparison
  // of react-redux.
  return useSelector(getEventId, shallowEqual);
}

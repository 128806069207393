/* @flow */

import { Skeleton } from '@mui/material';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './AuthenticationBase.style';

const useStyles = createUseThemeStyles(styles);

const AuthenticationValidateEmailFormLoading = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <Skeleton
          variant="rounded"
          width="100%"
          height={52}
          sx={{ marginTop: '15px' }}
        />
        <Skeleton
          variant="rectangular"
          width={42}
          height={18}
          sx={{ marginTop: '28px' }}
        />
        <Skeleton
          variant="rounded"
          width="100%"
          height={38}
          sx={{ marginTop: '8px' }}
        />

        <div className={classes.submit}>
          <Skeleton variant="rounded" width="100%" height={37} />
        </div>
        <div className={classes.signup}>
          <Skeleton variant="rounded" width={275} height={17} />
        </div>
      </div>
    </div>
  );
};

export default AuthenticationValidateEmailFormLoading;

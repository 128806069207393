/* @flow */
import { useIntl } from 'react-intl';

import AuthenticationContainer from 'src/shared/app/authentication/component/AuthenticationContainer';
import AuthenticationValidateEmailForm from 'src/shared/app/authentication/component/base/AuthenticationValidateEmailForm';
import messages from 'src/shared/app/authentication/l10n/validateEmailL10n';

const ValidateEmailMain = () => {
  const intl = useIntl();

  return (
    <AuthenticationContainer title={intl.formatMessage(messages.title)}>
      <AuthenticationValidateEmailForm />
    </AuthenticationContainer>
  );
};

export default ValidateEmailMain;

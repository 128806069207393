/* @flow */

import * as types from './uiAuthenticationActionTypes';

export function resetAuthenticationLoginSection(): Object {
  return {
    type: types.RESET_AUTHENTICATION_LOGIN_SECTION,
  };
}

export function showAuthenticationLoginPasswordSection(): Object {
  return {
    type: types.SHOW_AUTHENTICATION_LOGIN_PASSWORD_SECTION,
  };
}

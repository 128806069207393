/* @flow */

import { Fragment, useEffect } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { resetAuthenticationLoginSection } from 'src/shared/app/authentication/action/base/uiAuthenticationActions';
import { authenticationPasswordForm } from 'src/shared/app/authentication/constant/uiAuthenticationConstants';
import resetMessages from 'src/shared/app/authentication/l10n/resetPasswordL10n';
import { isPasswordReset as passwordReset } from 'src/shared/app/authentication/selector/base/appAuthenticationBaseSelectors';
import { getAuthenticationLoginSection } from 'src/shared/app/authentication/selector/base/uiAuthenticationSelectors';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import Notification from 'src/shared/app/notification/component/Notification';
import LazySignupMain from 'src/shared/app/signup/component/LazySignupMain';

import LoginPasswordForm from './LoginPasswordForm';
import LoginUsernameForm from './LoginUsernameForm';

const LoginMain = (): Node => {
  const dispatch = useDispatch();
  const section = useSelector(getAuthenticationLoginSection);

  const isPasswordReset = useSelector(passwordReset);

  const isPasswordSection = section === authenticationPasswordForm;

  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */

  const intl = useIntl();
  const { isSignupEnabled } = useFeatureDecisions();

  /*
   |----------------------------------------------------------------------------
   | Effects
   |----------------------------------------------------------------------------
   */
  useEffect(() => {
    dispatch(resetAuthenticationLoginSection());
  }, []);

  useEffect(() => {
    if (isSignupEnabled) {
      LazySignupMain.preload();
    }
  }, [isSignupEnabled]);

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */

  const bannerElt = isPasswordReset ? (
    <Notification>{intl.formatMessage(resetMessages.success)}</Notification>
  ) : null;

  const formElt = isPasswordSection ? (
    <LoginPasswordForm />
  ) : (
    <LoginUsernameForm />
  );

  return (
    <>
      {bannerElt}
      {formElt}
    </>
  );
};

export default LoginMain;

/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  getEventId,
  getEventLoginUsernameLabel,
  getEventLoginUsernameLegend,
  getEventLoginUsernamePlaceholder,
  getEventLoginUsernameText,
  getEventName,
  getEventUsernameFieldType,
} from '@braindate/domain/lib/event/util';
import {
  isMembershipOnboardingCompleted,
  isMembershipOptInCompleted,
} from '@braindate/domain/lib/membership/util';
import { isUserEmailValidated } from '@braindate/domain/lib/user/util';

import {
  login,
  resetInitialUrl,
  validateEmail,
} from 'src/shared/app/authentication/action/base/authenticationActions';
import { showAuthenticationLoginPasswordSection } from 'src/shared/app/authentication/action/base/uiAuthenticationActions';
import AuthenticationContainer from 'src/shared/app/authentication/component/AuthenticationContainer';
import AuthenticationUsernameForm from 'src/shared/app/authentication/component/base/AuthenticationUsernameForm';
import logInMessages from 'src/shared/app/authentication/l10n/logInL10n';
import {
  getAuthenticationUsername,
  getInitialUrl,
  isPasswordReset,
} from 'src/shared/app/authentication/selector/base/appAuthenticationBaseSelectors';
import { useCompleteOnboardingMutation } from 'src/shared/app/base/api/endpoint/membershipEndpoint';
import { useSendEmailVerificationEmailMutation } from 'src/shared/app/base/api/endpoint/usersEndpoint';
import { throwFormikSubmissionError } from 'src/shared/app/base/form/util/formUtil';
import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import {
  homeRoute,
  validateEmailRoute,
} from 'src/shared/app/base/route/setting/routeSettings';
import { getRoutePath } from 'src/shared/app/base/route/util/routeUtils';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import { isOnboardingCompletedOnDevice } from 'src/shared/app/onboarding/selector/uiOnboardingSelectors';
import useEvent from 'src/shared/domain/event/hook/useEvent';
import useEventId from 'src/shared/domain/event/hook/useEventId';

const LoginUsernameForm = (): Node => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const eventId = useEventId();

  const event = useEvent();
  const navigate = useNavigate();
  const isLegendHidden = useSelector(isPasswordReset);
  const username = useSelector(getAuthenticationUsername);
  const initialUrl = useSelector(getInitialUrl);
  const [validateEmailPath] = useRoutes(validateEmailRoute);

  const { areEmailNotificationsEnabled } = useFeatureDecisions();

  const [sendEmailVerificationEmail] = useSendEmailVerificationEmailMutation();
  const [completeOnboarding] = useCompleteOnboardingMutation();

  const eventName = getEventName(event, intl.locale);
  // $FlowIssue = type only defined for public events
  const type = getEventUsernameFieldType(event);
  const legend = getEventLoginUsernameLegend(event, intl.locale);
  const label = getEventLoginUsernameLabel(event, intl.locale);
  const placeholder = getEventLoginUsernamePlaceholder(event, intl.locale);
  const note = getEventLoginUsernameText(event, intl.locale);
  const isOnboardingCompleted = useSelector(isOnboardingCompletedOnDevice);

  const handleSubmit = async ({
    username: u,
    password,
  }: Object): Promise<any> => {
    if (!!u && !!password) {
      try {
        const { user, membership } = await dispatch(
          login(u, password, eventId),
        );

        if (areEmailNotificationsEnabled && !isUserEmailValidated(user)) {
          await sendEmailVerificationEmail(user.id);
          return navigate(validateEmailPath);
        }

        if (isOnboardingCompleted) {
          await completeOnboarding();
        }

        let route = getRoutePath(homeRoute);
        if (
          isMembershipOnboardingCompleted(membership) &&
          isMembershipOptInCompleted(membership) &&
          initialUrl
        ) {
          route = initialUrl;

          dispatch(resetInitialUrl());
        }

        navigate(route);
      } catch (e) {
        throwFormikSubmissionError(e);
      }
    }

    return dispatch(validateEmail(u, getEventId(event)))
      .then(() => dispatch(showAuthenticationLoginPasswordSection()))
      .catch((e) => throwFormikSubmissionError(e));
  };

  const legentElt = isLegendHidden
    ? ''
    : legend || intl.formatMessage(logInMessages.usernameLegend, { eventName });

  return (
    <AuthenticationContainer
      title={intl.formatMessage(logInMessages.title)}
      legend={legentElt}
    >
      <AuthenticationUsernameForm
        type={type}
        initialUsername={username}
        label={label}
        placeholder={placeholder}
        note={note}
        submitCta={intl.formatMessage(logInMessages.usernameSubmit)}
        handleSubmit={handleSubmit}
      />
    </AuthenticationContainer>
  );
};

export default memo<{}>(LoginUsernameForm);

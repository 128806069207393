/* @flow */

import { createSelector } from '@reduxjs/toolkit';

/*
|------------------------------------------------------------------------------
| INPUTS
|------------------------------------------------------------------------------
*/

const _segment = (state) => state.ui.authentication;

const _getAuthenticationLoginSection = (state) => _segment(state).loginSection;

/*
|------------------------------------------------------------------------------
| SELECTORS
|------------------------------------------------------------------------------
*/

export const getAuthenticationLoginSection: (state: Object) => string =
  createSelector([_getAuthenticationLoginSection], (x: string): string => x);
